import {ActionType} from "app/components/action/model/ActionType";
import {createContext} from "react";

export const ActionContext = createContext({
    action: undefined as ActionType | undefined,
    updateAction: (action: ActionType | undefined) => {
    },
    onModalClose: () => {
    }
});
