import {ActionContext} from "app/components/action/ActionContext";
import {Reset} from "app/components/action/model/Actions";
import {ActionType} from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import {Form, Formik, useFormikContext} from "formik";
import {ToggleButton, ToggleButtonGroup} from "lib/components";
import Button from "lib/components/Button/Button";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import {useContext} from "react";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, setFieldValue, isValid} = useFormikContext<Reset>();

    const handleChange = (
        _event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        setFieldValue("reset_type", newAlignment as "Hard" | "Soft");
    };

    return <Form>
        <DialogHeader>Reset</DialogHeader>
        <DialogContent>
            <ToggleButtonGroup
                color="primary"
                value={values.reset_type}
                exclusive
                onChange={handleChange}>
                <ToggleButton value="Hard">Hard reset</ToggleButton>
                <ToggleButton value="Soft">Soft reset</ToggleButton>
            </ToggleButtonGroup>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const ResetForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: Reset) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.Reset, params.identity, request);
            await OcppApiService.reset(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                reset_type: "Hard" as Reset["reset_type"],
            }}
            onSubmit={handleSubmit}>
            <InnerForm/>
        </Formik>
    );
};

export default ResetForm;
