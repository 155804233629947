import {ParsedQuery} from "query-string";
import {useLocation, useNavigate, useParams} from "react-router-dom";

interface Params extends ParsedQuery {
    identity: string;
}

const useRouter = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const params = useParams();

    const {identity} = params as Params;

    return {
        pathname,
        params: {
            identity,
        },
        navigate,
    };
};

export default useRouter;
