import React, {forwardRef, Ref} from 'react';

import MenuItem from '../Menu/partials/MenuItem';

import TextField from '../TextField';

import {DropdownProps} from './types';

const Dropdown = (
    {
        adornment,
        options = [],
        multiple = false,
        'data-testid': testid,
        ...props
    }: DropdownProps,
    ref: Ref<HTMLDivElement>
): React.JSX.Element => {
    return (
        <TextField
            select
            startAdornment={adornment}
            SelectProps={{
                MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                },
            }}
            inputProps={{'data-testid': testid}}
            ref={ref as Ref<HTMLDivElement>}
            {...props}
        >
            {options.map(({id, value: label, disabled}) => (
                <MenuItem key={id} value={id} disabled={disabled}>
                    {label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default forwardRef(Dropdown);
