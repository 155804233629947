import {GridColDef} from "@mui/x-data-grid";
import Typography from "lib/components/Typography";
import DateUtils from "app/components/common/DateUtils";
import Label from "lib/components/Label";
import * as React from "react";
import {Color} from "lib/types/color";

const getColor = (level?: string): Color => {
    switch (level) {
        case "Info":
            return "ocean";
        case "Trace":
            return "green";
        default:
            return "sand";
    }
};
export const getColumns=()=>{

const  columns: GridColDef[] = [
    {
        flex: 1,
        field: "timestamp",
        headerName: "Timestamp",
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
            params.value ?
                <Typography variant="caption">{DateUtils.formatDateString(params.value, "HH:mm:ss")}</Typography> : null
        )
    },
    {
        flex: 1,
        field: "level",
        headerName: "Level",
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
            <Label text={params.value ?? "unknown"} color={getColor(params.value)} />
        )
    },
    {
        flex: 6,
        field: "message",
        headerName: "Message",
    }
];
return columns;
}
