import {ActionContext} from "app/components/action/ActionContext";
import {ChangeConfiguration} from "app/components/action/model/Actions";
import {ActionType} from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import {FieldArray, Form, Formik, useFormikContext} from "formik";
import {Stack} from "lib/components";
import Box from "lib/components/Box/Box";
import Button from "lib/components/Button/Button";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import TextField from "lib/components/TextField/TextField";
import Typography from "lib/components/Typography";
import {useContext} from "react";
import * as yup from "yup";

function InnerForm() {

    const actionContext = useContext(ActionContext);

    const {values, handleChange, isValid} = useFormikContext<ChangeConfiguration>();

    return <Form>
        <DialogHeader>Change configuration</DialogHeader>
        <DialogContent>
            <FieldArray
                name="keys"
                render={(arrayHelpers) => (
                    <Stack direction="column" spacing="3rem">
                        {values.keys.map((key, index) => (
                            <Stack direction="row" spacing="1rem" key={index}>
                                <TextField
                                    fullWidth
                                    id={`keys.${index}.key`}
                                    name={`keys.${index}.key`}
                                    value={values.keys[index].key}
                                    onChange={handleChange}
                                    startAdornment={
                                        <Typography variant="caption">Key</Typography>
                                    }/>
                                <TextField
                                    fullWidth
                                    id={`keys.${index}.value`}
                                    name={`keys.${index}.value`}
                                    value={values.keys[index].value}
                                    onChange={handleChange}
                                    startAdornment={
                                        <Typography variant="caption">Value</Typography>
                                    }/>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    onClick={() => arrayHelpers.remove(index)}
                                    icon="Minus"/>
                            </Stack>
                        ))}
                        <Box>
                            <Button
                                variant="secondary"
                                onClick={() => arrayHelpers.push("")}
                                icon="Plus"/>
                        </Box>
                    </Stack>
                )}/>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
}

const ChangeConfigurationForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: ChangeConfiguration) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.ChangeConfiguration, params.identity, request);
            await OcppApiService.changeConfiguration(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                keys: [
                    {key: "", value: "",},
                ],
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object({
                keys: yup.array().min(1),
            })}>
            <InnerForm/>
        </Formik>
    );
};

export default ChangeConfigurationForm;
