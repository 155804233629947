import MuiTextField, {OutlinedTextFieldProps} from '@mui/material/TextField';
import React, {forwardRef, Ref, useRef} from 'react';

import theme from '../../styles/theme';

import InputAdornment from '../InputAdornment';

export type ColorTypes = 'primary' | 'white';

export type TextFieldProps = {
    endAdornment?: string | React.JSX.Element;
    fullWidth?: boolean;
    startAdornment?: string | React.JSX.Element;
    color?: ColorTypes;
    error?: boolean;
    helperText?: string;
    'data-testid'?: string;
} & Omit<
    OutlinedTextFieldProps,
    'variant' | 'color' | 'fullWidth' | 'error' | 'helperText' | 'hiddenLabel'
>;

const TextField = (
    {
        endAdornment,
        fullWidth = true,
        startAdornment,
        color = 'primary',
        error = false,
        helperText = '',
        sx = {},
        InputLabelProps = {},
        ...props
    }: TextFieldProps,
    ref: Ref<HTMLDivElement>
): React.JSX.Element => {
    const muiTextField = useRef<HTMLDivElement>(null);

    return (
        <MuiTextField
            variant="outlined"
            fullWidth={fullWidth}
            size="small"
            error={error}
            helperText={helperText}
            onClick={() => muiTextField.current?.focus()}
            inputRef={muiTextField}
            InputProps={{
                endAdornment: endAdornment ? (
                    <InputAdornment position="end">{endAdornment}</InputAdornment>
                ) : undefined,
                startAdornment: startAdornment ? (
                    <InputAdornment position="start">{startAdornment}</InputAdornment>
                ) : undefined,
                ...(props.InputProps ?? {}),
                notched: false,
            }}
            InputLabelProps={{shrink: true, ...InputLabelProps}}
            sx={{
                color: theme.palette.text.primary,
                label: {
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    lineHeight: '1.2rem',
                    marginBottom: '1rem',
                    color: theme.palette.night[100],
                },
                '.MuiFormLabel-root.Mui-focused, .MuiFormLabel-root.Mui-error': {
                    color: 'inherit',
                },
                '.MuiInputLabel-shrink': {
                    transform: 'translate(0, 0) scale(1)',
                },
                '.MuiInputLabel-formControl': {
                    position: 'relative',
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.night[40],
                    borderRadius: '0.8rem',
                },
                '.MuiInputBase-input': {
                    padding: '0 1.2rem !important',
                    height: '4rem',
                    fontSize: '1.4rem',
                },
                '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.night[50],
                },
                '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: theme.palette.night[50],
                    boxShadow: `0px 0px 0px 4px ${theme.palette.ocean[10]}`,
                },
                '.MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                        boxShadow: `0px 0px 0px 4px ${theme.palette.red[8]}`,
                    },
                '.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.red[100],
                },
                '.MuiInputBase-root.Mui-disabled': {
                    backgroundColor: theme.palette.night[10],
                },
                '.MuiInputLabel-root.Mui-disabled': {
                    color: theme.palette.night[100],
                },
                '.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.night[40],
                },
                '.MuiFormHelperText-root': {
                    fontSize: '1.2rem',
                    fontWeight: 400,
                    color: theme.palette.night[100],
                },
                '.MuiFormHelperText-root.Mui-error': {
                    color: theme.palette.red[100],
                },
                '.MuiFormHelperText-contained': {
                    marginLeft: 0,
                },
                '.MuiSelect-select': {
                    height: '4rem !important',
                    display: 'flex !important',
                    alignItems: 'center !important',
                },
                ...sx,
            }}
            ref={ref}
            {...props}
        />
    );
};

export default forwardRef(TextField);
