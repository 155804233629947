import {ChargePoint} from "app/components/chargepoint/model/ChargePoint";
import DateUtils from "app/components/common/DateUtils";
import {Grid, List, ListItem, ListItemText} from "lib/components";
import Box from "lib/components/Box";

import Paper from "lib/components/Paper";

interface ExtendedInformationProps {
    chargePoint: ChargePoint
}

const ExtendedInformation = (props: ExtendedInformationProps) => {
    const data = [
        [
            {
                key: "Heartbeat Interval",
                value: props.chargePoint.heartbeatInterval ?? ""
            },
            {
                key: "Firmware version",
                value: props.chargePoint.firmwareVersion,
            },
            {
                key: "Firmware status",
                value: props.chargePoint.firmwareStatus,
            },
            {
                key: "Meter serial number",
                value: props.chargePoint.meterSerialNumber,
            },
            {
                key: "Meter type",
                value: props.chargePoint.meterType,
            },
            {
                key: "Error code",
                value: props.chargePoint.errorCode,
            },
            {
                key: "Created at",
                value: DateUtils.formatDate(props.chargePoint.createdAt, "dd.MM.yyyy HH:mm:ss")
            },
            {
                key: "Updated at",
                value: DateUtils.formatDate(props.chargePoint.updatedAt, "dd.MM.yyyy HH:mm:ss")
            },
        ],
        [
            {
                key: "ICCID",
                value: props.chargePoint.iccid,
            },
            {
                key: "IMSI",
                value: props.chargePoint.imsi,
            },
            {
                key: "Info",
                value: props.chargePoint.info,
            },
            {
                key: "Vendor error code",
                value: props.chargePoint.vendorErrorCode,
            },
            {
                key: "Vendor ID",
                value: props.chargePoint.vendorId,
            },
            {
                key: "Diagnostics status",
                value: props.chargePoint.diagnosticsStatus,
            },
            {
                key: "Meter Wh",
                value: props.chargePoint.meterWh,
            },
        ],
    ];

    return <Box sx={{marginTop: "2rem"}}>
        <Grid container spacing="2rem">
            {data.map((rows, dataIndex) => (
                <Grid item xs={12} md={6} xl={6} key={dataIndex}>
                    <Paper>
                        <List>
                            {rows.map(({key, value}, rowIndex) => (
                                <ListItem
                                    key={key}
                                    divider={rowIndex + 1 !== rows.length}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <ListItemText sx={{textAlign: "left"}}>
                                        {key}
                                    </ListItemText>
                                    <ListItemText sx={{textAlign: "right"}}>
                                        {value}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    </Box>
};

export default ExtendedInformation;
