import {Download} from "@mui/icons-material";
import {colors, IconButton, Tooltip} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {ChargePointLogEntry} from "app/components/log/ChargePointLogEntry";

import * as React from "react";
import { useLogParser, useLogs} from "./LogsContext";
import {getColumns} from "app/components/log/columns";



const Logs = () => {
    const escapeString = React.useCallback((value: string | undefined | null): string | undefined => {
        return value?.replaceAll("\n", "")?.replaceAll("\"", "\"\"")
    }, [])

    const logToCsvLine = React.useCallback((log: ChargePointLogEntry): string => {
        return "\"" + escapeString(log.timestamp) + "\",\"" + escapeString(log.message) + "\"\n"
    }, [escapeString])

    const chargePointLogs = useLogs();
    const logParser = useLogParser();

    const findRelatedLog = React.useCallback((log: ChargePointLogEntry): ChargePointLogEntry[] => {
        const jsonData = logParser(log);
        if (!jsonData || typeof jsonData[1] !== "string") {
            return [log];
        }
        const relatedIdPart = jsonData[1];
        const relatedLogs = chargePointLogs.filter((otherLog) => {
            const otherJsonData = logParser(otherLog);
            return otherJsonData && typeof otherJsonData[1] === "string" && otherJsonData[1] === relatedIdPart && otherLog !== log;
        });
        return [log, ...relatedLogs];
    }, [chargePointLogs, logParser]);

    const sortedLogs = React.useMemo(() => {
        return [...chargePointLogs].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    }, [chargePointLogs]);

    const onExportButtonClicked = React.useCallback(async () => {

        // Create CSV Header
        let data = "timestamp,logMessage\n";

        for (let log of sortedLogs) {
            data += logToCsvLine(log)
        }

        const blob = new Blob([data], {
            type: 'text/csv'
        })

        const file = window.URL.createObjectURL(blob);

        window.location.assign(file);

    }, [logToCsvLine, sortedLogs])

    const [relatedRows, setRelatedRows] = React.useState<ChargePointLogEntry[]>([]);


    const relatedRowCSS = React.useMemo(() => {
        return Object.fromEntries(
            relatedRows.map((rel) => [
                `& .MuiDataGrid-row[data-id="${rel.timestamp + (logParser(rel) ? logParser(rel)[1] : "")}"]`,
                {
                    backgroundColor: colors.grey[200],

                }
            ])
        );
    }, [relatedRows, logParser]);
    return <div>
        <Tooltip title={"Download Logs"}>
            <IconButton onClick={onExportButtonClicked}>
                <Download />
            </IconButton>
        </Tooltip>
        <DataGrid
            sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                ...relatedRowCSS,
            }}
            rows={sortedLogs}
            columns={getColumns()}
            getRowId={(row)=>{
                return   row.timestamp+(logParser(row)? logParser(row)[1]: "")}}
            onRowClick={(params) => {
                setRelatedRows(findRelatedLog(params.row))
            }}
            getRowHeight={() => {
                return "auto"
            }}
            rowSelection={false}
            disableColumnMenu={false} />
    </div>
};

export default Logs;
