import {InputAdornment as MuiInputAdornment, InputAdornmentProps as MuiInputAdornmentProps,} from '@mui/material';
import React from "react";

export type InputAdornmentProps = MuiInputAdornmentProps;

const InputAdornment = ({
                            sx = {},
                            ...props
                        }: InputAdornmentProps): React.JSX.Element => {
    return <MuiInputAdornment sx={{color: 'text.primary', ...sx}} {...props} />;
};

export default InputAdornment;
