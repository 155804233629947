import MuiBox, {BoxProps as MuiBoxProps} from '@mui/material/Box';
import React, {forwardRef, Ref} from 'react';

export interface BoxProps extends MuiBoxProps {
    ref?: Ref<HTMLElement>;
}

const Box = (props: BoxProps, ref: Ref<HTMLElement>): React.JSX.Element => {
    return <MuiBox ref={ref} {...props} />;
};

export default forwardRef(Box);
