import {ActionContext} from "app/components/action/ActionContext";
import {DataTransfer} from "app/components/action/model/Actions";
import {ActionType} from "app/components/action/model/ActionType";
import AnalyticsHelper from "app/components/common/AnalyticsHelper";
import useErrorHandler from "app/hooks/useErrorHandler";
import useRouter from "app/hooks/useRouter";
import OcppApiService from "app/services/OcppApiService";
import {Form, Formik, useFormikContext} from "formik";
import {Stack} from "lib/components";
import Button from "lib/components/Button/Button";
import {DialogContent, DialogFooter, DialogHeader} from "lib/components/Dialog";
import TextField from "lib/components/TextField/TextField";
import {useContext} from "react";
import * as yup from "yup";

const InnerForm = () => {
    const actionContext = useContext(ActionContext);
    const {values, handleChange, isValid, errors} = useFormikContext<DataTransfer>();

    return <Form>
        <DialogHeader>Data Transfer</DialogHeader>
        <DialogContent>
            <Stack direction="column" spacing="3rem">
                <TextField
                    label="Vendor ID"
                    fullWidth
                    id="vendorId"
                    name="vendorId"
                    value={values.vendorId}
                    onChange={handleChange}
                    error={Boolean(errors.vendorId)}
                    helperText={errors.vendorId ? errors.vendorId : ""}/>
                <TextField
                    label="Message ID"
                    fullWidth
                    id="messageId"
                    name="messageId"
                    value={values.messageId}
                    onChange={handleChange}
                    error={Boolean(errors.messageId)}
                    helperText={errors.messageId ? errors.messageId : ""}/>
                <TextField
                    label="Data"
                    fullWidth
                    id="data"
                    name="data"
                    value={values.data}
                    onChange={handleChange}
                    error={Boolean(errors.data)}
                    helperText={errors.data ? errors.data : ""}/>
            </Stack>
        </DialogContent>
        <DialogFooter>
            <Button onClick={actionContext.onModalClose} variant="secondary">
                Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!isValid}>
                Submit
            </Button>
        </DialogFooter>
    </Form>
};

const DataTransferForm = () => {
    const {params} = useRouter();
    const actionContext = useContext(ActionContext);
    const {onError} = useErrorHandler();

    const handleSubmit = async (request: DataTransfer) => {
        try {
            AnalyticsHelper.trackCommandStart(ActionType.DataTransfer, params.identity, request);
            await OcppApiService.dataTransfer(request)
            actionContext.onModalClose()
        } catch (error) {
            onError(error, "Command Failed: ")
        } finally {

        }
    }

    return (
        <Formik
            initialValues={{
                identity: params.identity,
                data: undefined,
                messageId: undefined,
                vendorId: "",
            }}
            validationSchema={yup.object({
                vendorId: yup.string().required(),
                messageId: yup.string().optional(),
                data: yup.string().optional(),
            })}
            onSubmit={handleSubmit}>
            <InnerForm/>
        </Formik>
    );
};

export default DataTransferForm;
